

.center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px;
    border-color: gray;
}

.figure-box {
    border: 1px solid #000; 
    padding: 10px; 
    margin: 10px; 
    
}