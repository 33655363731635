// Custom color variables

$teal: #0085A1;

$primary: $teal;

$link: #5197ed;

$hover: #0085A1;

$table_border: $teal;