// _tables.scss

table {
    border: 1px solid $table_border; 
    padding: 10px;         
  
    th,
    td {
      padding: 20px;
      border: 1px dotted $table_border;
    }
  }
  